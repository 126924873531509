import { Box, Divider, Grid, makeStyles, Typography } from '@material-ui/core'
import { BillMode, VzfpdfData, VzfpdfEntry } from 'graphql/types'
import * as React from 'react'
import { useTranslation } from 'react-i18next'

const useStyles = makeStyles(() => ({
    Divider: {
        height: 2,
        backgroundColor: '#C4C4C4',
    },
}))

export interface VZFSummaryProps {
    data: VzfpdfData
    B2B: boolean
}

const VZFSummary = (props: VZFSummaryProps): JSX.Element => {
    const { t } = useTranslation()
    const {
        data: {
            costs,
            functionalFeaturesDisability,
            optionalCosts,
            costHint1,
            costHint2,
            costHint3,
            vzfID,
            internet,
            services,
            devices,
            contractTerms,
        },
        B2B,
    } = props
    const classes = useStyles()
    const contentPaddingLeft = 10
    const yourID = t('vzf.vzfYourID')
    const displayOptionalCosts = true
    return (
        <Box display={'flex'} flexDirection={'column'}>
            <Box pl={`${contentPaddingLeft}px`}>
                <Grid container justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} md={10}>
                        <Typography
                            variant={'h3'}
                            className={'VZFSummaryHeadline'}
                            dangerouslySetInnerHTML={{ __html: yourID }}
                        />
                    </Grid>

                    <Grid item xs={12} md={2}>
                        <Box flex={1} display="flex" justifyContent={{ xs: 'flex-start', md: 'flex-end' }}>
                            <Typography variant={'h3'} className={'VZFSummaryHeadline'}>
                                {vzfID}
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
                <Box pt={'23px'}>
                    <Box display={'flex'} flexDirection={'row'}>
                        <Typography variant={'h4'} className={'VZFSummaryHeadline'}>
                            {t('vzf.vzfProducts')}
                        </Typography>
                    </Box>
                    {/* <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                        {t('vzf.vzfServices')}
                    </Typography> */}
                    <Box pb={'20px'}>
                        {services.map(
                            (service, serviceIndex): JSX.Element => (
                                <React.Fragment key={serviceIndex}>
                                    {/* <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                                        {service.title}
                                    </Typography> */}
                                    {service.information.map((info, index) => (
                                        <Typography
                                            key={serviceIndex + '_' + index}
                                            variant={'h5'}
                                            dangerouslySetInnerHTML={{
                                                __html: info,
                                            }}
                                        />
                                    ))}
                                </React.Fragment>
                            ),
                        )}
                    </Box>
                    {devices.length > 0 && (
                        <>
                            {/* <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                                {t('vzf.vzfDevices')}
                            </Typography> */}
                            <Box>
                                {devices.map(
                                    (device, index): JSX.Element => (
                                        <Typography
                                            key={index}
                                            variant={'h5'}
                                            dangerouslySetInnerHTML={{
                                                __html: device,
                                            }}
                                        />
                                    ),
                                )}
                            </Box>
                        </>
                    )}
                    {internet && (
                        <>
                            <Box pt={'23px'}>
                                <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                                    {t('vzf.vzfInternetSpeed')}
                                </Typography>
                            </Box>

                            <Grid container justifyContent="space-between" alignItems="flex-start">
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{t('vzf.vzfTransferRates')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{t('vzf.vzfOnDownload')}</Typography>
                                </Grid>

                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{t('vzf.vzfOnUpload')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{t('vzf.vzfMaximum')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{`${internet.download.max}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{`${internet.upload.max}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{t('vzf.vzfNormally')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{`${internet.download.normal}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{`${internet.upload.normal}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{t('vzf.vzfMinimum')}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{`${internet.download.min}`}</Typography>
                                </Grid>
                                <Grid item xs={12} sm={4}>
                                    <Typography variant={'h5'}>{`${internet.upload.min}`}</Typography>
                                </Grid>
                            </Grid>
                            <Box pt={'23px'}>
                                <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                                    {t('vzf.annotation')}
                                </Typography>
                            </Box>
                            <Box>
                                <Typography
                                    variant={'h5'}
                                    dangerouslySetInnerHTML={{
                                        __html: internet.speedTerm,
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </Box>
            </Box>
            <Box py={'23px'}>
                <Divider className={classes.Divider} />
            </Box>
            <Box pl={`${contentPaddingLeft}px`}>
                <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                    {B2B === true ? t('vzf.vzfCostTitleB2B') : t('vzf.vzfCostTitle')}
                </Typography>
                {costs.categoryCost.map((category, categoryIndex) => (
                    <React.Fragment key={categoryIndex}>
                        <Box display={'flex'} flexDirection={'row'} width={1}>
                            <Box flex={1}>
                                <Typography
                                    variant={'h5'}
                                    className={'VZFSummaryHeadline'}
                                    dangerouslySetInnerHTML={{
                                        __html: category.title,
                                    }}
                                />
                            </Box>
                        </Box>
                        {category.entries.map((entry, index) => {
                            let billMode = ''
                            if (entry.billMode === BillMode.ONE_TIME) {
                                billMode = ' ' + t('ONE_TIME')
                            } else if (entry.billMode === BillMode.RECURRING_MONTHLY) {
                                billMode = ' ' + t('RECURRING_MONTHLY_SHORT')
                            }
                            return (
                                <Grid container justifyContent="space-between" alignItems="flex-start" key={index}>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant={'h5'}>{entry.title}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sm={6}>
                                        <Typography variant={'h5'}>{entry.value + billMode}</Typography>
                                    </Grid>
                                </Grid>
                            )
                        })}
                    </React.Fragment>
                ))}
                <Box marginBottom={2} />
                <Grid container justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                            {t('monthlyCost')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'h5'}>{costs.monthlyCost}</Typography>
                    </Grid>
                    {costs.monthlyItems.map((entry: VzfpdfEntry, index: number) => {
                        return (
                            <Grid key={index} container justifyContent="space-between" alignItems="flex-start">
                                <Grid item xs={12} sm={6}>
                                    <Typography variant={'h5'}>
                                        {t('fromMonth', {
                                            month: entry.title,
                                        })}
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <Typography variant={'h5'}>{entry.value}</Typography>
                                </Grid>
                            </Grid>
                        )
                    })}
                </Grid>
                <Grid container justifyContent="space-between" alignItems="flex-start">
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                            {t('oneTimeCosts')}
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography variant={'h5'}>{costs.oneTimeCost}</Typography>
                    </Grid>
                    {costs.oneTimeItems.map((entry: VzfpdfEntry, entryIndex) => (
                        <React.Fragment key={entryIndex}>
                            <Grid item xs={12} sm={6}>
                                <Typography variant={'h5'}>{entry.title}</Typography>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Typography variant={'h5'}>{entry.value}</Typography>
                            </Grid>
                        </React.Fragment>
                    ))}
                </Grid>
                {displayOptionalCosts && optionalCosts.length > 0 && (
                    <Box pt={'15px'}>
                        <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                            {t('vzf.noticeHeadline')}
                        </Typography>
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: costHint1,
                            }}
                            variant={'h5'}
                        />
                        <br />
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: costHint2,
                            }}
                            variant={'h5'}
                        />
                        <br />
                        {optionalCosts.map((cost, i) => (
                            <Box flex={1} display={'flex'} flexDirection={'row'} key={i}>
                                <Box flex={1.5}>
                                    <Typography variant={'h5'}>{cost.title}</Typography>
                                </Box>
                                <Box flex={1}>
                                    <Typography variant={'h5'}>{cost.value}</Typography>
                                </Box>
                            </Box>
                        ))}
                        <br />
                        <Typography
                            dangerouslySetInnerHTML={{
                                __html: costHint3,
                            }}
                            variant={'h5'}
                        />
                    </Box>
                )}
            </Box>
            <Box py={'23px'}>
                <Divider className={classes.Divider} />
            </Box>
            <Box pl={`${contentPaddingLeft}px`}>
                {/* <Typography variant={'h1'}>WO?</Typography> */}
                <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                    {t('vzf.vzfContractTermTitle')}
                </Typography>
                {contractTerms.map((contractTerm, index, array) => (
                    <span key={index}>
                        <Typography variant={'h5'} style={{ marginBottom: index === array.length - 1 ? 0 : 8 }}>
                            {contractTerm}
                        </Typography>
                    </span>
                ))}
                <br />
                <Typography variant={'h5'}>{t('vzf.vzfContractTermNote')}</Typography>
            </Box>
            <Box py={'23px'}>
                <Divider className={classes.Divider} />
            </Box>
            <Box pl={`${contentPaddingLeft}px`}>
                <Typography variant={'h5'} className={'VZFSummaryHeadline'}>
                    {t('vzf.vzfMiscellaneousTitle')}
                </Typography>
                <Typography variant={'h5'}>{t('vzf.vzfMiscellaneousText1')}</Typography>
                <Typography variant={'h5'}>
                    {t('vzf.vzfMiscellaneousText2', { functionalFeaturesDisability })}
                </Typography>
                <Typography variant={'h5'}>{t('vzf.vzfMiscellaneousText3')}</Typography>
            </Box>
        </Box>
    )
}

export default VZFSummary
